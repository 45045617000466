<template>
  <div class="flex items-center justify-center login_wrapper">
    <div class="login">
      <img class="bg_img" src="../../assets/login/bg_img.png" alt="" />
      <div class="main" v-loading="loading">
        <div class="title">Iniciar sesión</div>

        <div class="cell">
          <div class="label">Usuario</div>
          <input v-model="query.loginName" type="text" class="input" />
        </div>
        <div class="cell mt-10px">
          <div class="label">Contraseña</div>
          <input v-model="query.loginPwd" type="password" class="input" />
        </div>
        <div class="mt-16px flex items-center w-497px justify-between ml-16px">
          <div class="register" @click="$router.push('/register')">Registrarse</div>
          <div class="register" @click="$router.push('/reset')">Olvide mi contraseña</div>
        </div>
        <div
          class="login-btn flex items-center justify-center"
          :class="{ disabled: disabled }"
          @click="login"
        >
          Ingresar
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="1200px">
      <register-info :editQuery="editQuery"></register-info>
    </el-dialog>
  </div>
</template>

<script>
import registerInfo from './register-info'
export default {
  components: {
    registerInfo,
  },
  data() {
    return {
      query: {
        loginName: '',
        loginPwd: '',
      },
      disabled: false,
      loading: false,
      dialogVisible: false,
      editQuery: '',
    }
  },
  methods: {
    login() {
      if (!this.query.loginName || !this.query.loginPwd) {
        this.$message('请输入用户名和密码')
        return
      }
       this.loading = true

        this.axios.post('/member/auth/login', this.query).then((res) => {
        let data = res.data.result
        // 在这存储token是因为遇到要修改注册信息的用户提交时需要拿token，但为了防止下次打开进入首页，在main.js中对用户状态进行判断来控制登录行为
        this.$store.commit('SET_TOKEN', data.token)
        this.$store.commit('SET_INFO', data.memberDetail)
        let accountStatus = data.memberDetail.registrationStatus
        if (accountStatus !== 2) {
          if (accountStatus === 1) {
            this.$message(
              'La cuenta aún está bajo verificación, por favor espere pacientemente'
            ) //该账号还在审核中，请耐心等待
            this.loading = false
            return
          } else if (accountStatus === 3) {
            this.$message(
              'La cuenta no ha sido verificada , Por favor complete la información de nuevo y envíela para su verificación'
            ) //该账号审核未通过，请重新完善信息后再提交审核
            console.log('登录页跳转')
            setTimeout(() => {
              this.editQuery = data.memberDetail
              this.dialogVisible = true
            }, 3000)
            this.loading = false
            return
          }
          this.loading = false
          return
        }
        this.loading = false
        this.$router.push('/')
      }).catch(() => {
         this.loading = false
      })
       
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  position: relative;
  .bg_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .main {
    width: 609px;
    height: 585px;
    background: #ffffff;
    box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 315px;
    right: 151px;
    border-radius: 20px;
    box-sizing: border-box;
    padding-top: 45px;
    padding-left: 56px;
    .title {
      // width: 292px;
      height: 61px;
      font-family: Nexa, Nexa;
      font-weight: bold;
      font-size: 47px;
      color: #64b802;
      line-height: 71px;
      text-align: left;
      font-style: normal;
      white-space: nowrap;
      margin-bottom: 55px;
    }
    .cell {
      .label {
        height: 35px;
        font-family: Nexa, Nexa;
        font-weight: 400;
        font-size: 27px;
        color: #383838;
        line-height: 41px;
        padding-left: 26px;
      }
      .input {
        width: 497px;
        margin-top: 10px;
        height: 54px;
        background: #e6e6e6;
        border: none !important;
        border-radius: 50px;
        padding: 0 20px;
        font-size: 24px;
        &:focus {
          outline: none;
        }
      }
    }
    .register {
      height: 23px;
      font-family: Nexa, Nexa;
      font-weight: normal;
      font-size: 18px;
      color: #29abe2;
      line-height: 27px;
      cursor: pointer;
      text-decoration: underline;
    }
    .login-btn {
      width: 289px;
      height: 54px;
      background: #64b802;

      font-family: Nexa, Nexa;
      font-weight: normal;
      font-size: 27px;
      color: #ffffff;
      line-height: 41px;
      margin: auto;
      margin-top: 65px;
      border-radius: 27px;
      cursor: pointer;
    }
    .disabled {
      background: gray;
      color: #ffffff;
    }
  }
}

.login_wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
